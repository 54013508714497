// 先执行原有的 WebSocket 代理逻辑
(function () {
  const OriginalWebSocket = window.WebSocket

  const PROXY_SERVER = 'wss://wss.offer.cool'

  class MyWebSocket extends OriginalWebSocket {
    constructor(url, protocols) {
      // 仅当 url是 daily.co 这类
      if (typeof url === 'string' && url.includes('wss.daily.co:443')) {
        // 对原始URL做 encode
        const encodedTarget = encodeURIComponent(url)
        // 拼到本地代理
        // const newUrl = `wss://${PROXY_SERVER}?target=${encodedTarget}`
        const newUrl = PROXY_SERVER

        // console.log('[MonkeyPatch] Rewriting WS from:', url, ' -> ', newUrl)
        super(newUrl, protocols)
      }
      else {
        // 不处理其他
        super(url, protocols)
      }
    }
  }

  window.WebSocket = MyWebSocket
})();

// 新增: HTTP 请求拦截
(function () {
  const originalFetch = window.fetch

  // const PROXY_SERVER = 'daily.offer.cool'
  const PROXY_SERVER = 'gs.offer.cool'

  // window.fetch = async function (url, options) {
  //   if (typeof url === 'string' && (
  //     // url.includes('c.daily.co') ||
  //     url.includes('gs.daily.co')
  //   )) {
  //     // const proxyUrl = `https://${PROXY_SERVER}/proxy/${url}`
  //     const newUrl = url.replaceAll('gs.daily.co', PROXY_SERVER)
  //     return originalFetch(newUrl, options)
  //   }
  //   return originalFetch(url, options)
  // }

  // 拦截 XHR 请求
  const originalXHROpen = XMLHttpRequest.prototype.open
  XMLHttpRequest.prototype.open = function (method, url, ...rest) {
    if (typeof url === 'string' && (
      url.includes('c.daily.co')
      || url.includes('gs.daily.co')
    )) {
      // url = `http://localhost:3000/proxy/${url}`
      url = url.replaceAll('gs.daily.co', PROXY_SERVER)
    }
    return originalXHROpen.call(this, method, url, ...rest)
  }
})()
